import React, { useEffect, useState } from 'react'
import * as ReactDOM from 'react-dom'
import { Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import Image from 'next/image'
import theme, { SECONDARY_COLOR, COLOR, FONTS } from 'src/styles/theme'
import { rem } from 'src/common/utils/css/index'
import sanitize from 'src/common/utils/js/sanitize'
import Video from 'src/common/components/video/Video'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'
import FbIcon from 'src/components/icons/FbIcon'
import Mail from 'src/components/icons/Mail'
import Print from 'src/components/icons/Print'
import XCorp from 'src/components/icons/XCorp'

const useStyles = makeStyles(theme)((defaultProps, prop) => {
  const {
    indent,
    background_color,
    show_page_sharing_options,
    show_print_option,
    show_information_panel,
  } = prop
  return {
    container: {
      backgroundColor:
        background_color === 'background'
          ? COLOR.LIGHT_BACKGROUND
          : COLOR.NEAR_WHITE,
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        paddingLeft:
          indent === true &&
          show_page_sharing_options === false &&
          show_print_option === false
            ? rem(119)
            : rem(48),
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft:
          indent === true &&
          show_page_sharing_options === false &&
          show_print_option === false
            ? rem(252)
            : rem(156),
      },
    },
    wrapper: {
      padding: `0 ${rem(24)}`,
      paddingTop:
        show_page_sharing_options === true || show_print_option === true
          ? rem(0)
          : rem(40),
      paddingBottom: show_information_panel ? rem(24) : rem(40),
      [theme.breakpoints.up('md')]: {
        padding: `${rem(24)} ${rem(40)}`,
        paddingTop:
          show_page_sharing_options === true || show_print_option === true
            ? rem(0)
            : rem(40),
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(64)} ${rem(0)}`,
        width:
          // eslint-disable-next-line no-nested-ternary
          show_page_sharing_options === false && show_print_option === false
            ? indent
              ? '53.03%'
              : '66.1%'
            : '55.7%',
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(0)}`,
        width:
          // eslint-disable-next-line no-nested-ternary
          show_page_sharing_options === false && show_print_option === false
            ? indent
              ? '54.55%'
              : '57.60%'
            : '50.5%',
      },
      '& hr': {
        width: '100%',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        marginBottom: rem(24),
        background: COLOR.BAKER_BEACH_GRAY,
        height: rem(2),
        border: 0,
      },
      '& h2': {
        ...theme.typography.h2,
        fontWeight: 500,
        fontSize: `${rem(40)}`,
        lineHeight: `${rem(36)}`,
        display: 'flex',
        color: theme.palette.primary.dark,
        flex: 'none',
        order: '1',
        alignSelf: 'stretch',
        flexGrow: '0',
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: `${rem(24)}`,
      },
      '& h3': {
        ...theme.typography.h3,
        color: theme.palette.primary.dark,
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: `${rem(16)}`,
      },
      '& h4': {
        ...theme.typography.h4,
        color: theme.palette.primary.dark,
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: `${rem(16)}`,
      },
      '& h5': {
        ...theme.typography.h5,
        color: theme.palette.primary.dark,
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: `${rem(24)}`,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
      '& p': {
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: `${rem(24)}`,
        fontSize: `${rem(16)}`,
        fontFamily: FONTS.TABLET_GOTHIC,
      },
      '.videoWrapperDiv': {
        paddingBottom: `${rem(24)}`,
      },
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
      '& img.aligncenter': {
        display: 'block',
        margin: '0 auto',
        padding: `${rem(16)}`,
      },
      '& img.alignleft': {
        float: 'left',
        padding: `${rem(6)} ${rem(16)} ${rem(16)} 0`,
      },
      '& img.alignright': {
        float: 'right',
        padding: `${rem(6)} 0 ${rem(16)} ${rem(16)}`,
      },
      '& body': {
        ...theme.typography.body,
      },
      '& figcaption': {
        ...theme.typography.helperText,

        paddingBottom: `${rem(32)}`,
      },
      '& figure': {
        display: 'contents',
      },
      '.videoWrapperBig': {
        aspectRatio: '16/9',
      },
      '.videoWrapperSmall': {
        aspectRatio: '16/9',
        [theme.breakpoints.up('md')]: {
          width: '65.46%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '65.53%',
        },
        [theme.breakpoints.up('xl')]: {
          width: '48.52%',
        },
      },
      '& ol': {
        paddingLeft: '1rem',
        color: COLOR.MILD_GRAY,
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: rem(24),
        fontFamily: FONTS.TABLET_GOTHIC,
        fontSize: rem(16),
      },
      '& ul': {
        paddingLeft: '1rem',
        color: COLOR.MILD_GRAY,
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: rem(24),
        fontFamily: FONTS.TABLET_GOTHIC,
        fontSize: rem(16),
      },
      '.numbered-list': {
        ...theme.typography.h4,
        fontSize: `${rem(18)} !important`,
        color: theme.palette.primary.main,
        fontWeight: '500',
        letterSpacing: '0',
        textTransform: 'none',
        paddingLeft: '1rem',
        marginBlockStart: '0',
        marginBlockEnd: '0',
        paddingBottom: rem(24),
        [theme.breakpoints.up('lg')]: {
          fontSize: `${rem(24)} !important`,
        },
        '& li': {
          paddingLeft: '.85rem',
        },
        '& h4': {
          fontSize: rem(18),
          color: theme.palette.primary.main,
          paddingBottom: rem(0),
          fontWeight: '500',
          lineHeight: '150%',
          [theme.breakpoints.up('lg')]: {
            fontSize: rem(24),
            lineHeight: '111%',
          },
        },
        '& p': {
          color: COLOR.MILD_GRAY,
          marginBlockStart: '0',
          marginBlockEnd: '0',
          paddingBottom: rem(16),
        },
      },
      '.underline-list': {
        listStyle: 'none',
        marginTop: rem(-8),
        paddingLeft: rem(30),
        '& h4': {
          fontSize: rem(18),
          color: theme.palette.primary.main,
          display: 'inline-block',
          paddingBottom: rem(5),
          position: 'relative',
          fontWeight: '500',
          '&:before': {
            position: 'absolute',
            content: '""',
            width: '80%',
            height: '2px',
            bottom: '0',
            borderBottom: `4px solid ${SECONDARY_COLOR.MAIN[60]}`,
            borderRadius: `2px`,
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: rem(24),
          },
        },
        '& p': {
          color: COLOR.MILD_GRAY,
          marginBlockStart: '0',
          marginBlockEnd: '0',
          paddingBottom: rem(6),
        },
      },
      '.icon-list': {
        paddingLeft: '0 !important',
        listStyleType: 'none',
        paddingInlineStart: '0',
        '& h5': {
          fontSize: rem(18),
          fontFamily: FONTS.ROC_GROTESK,
          color: theme.palette.primary.dark,
          paddingBottom: rem(0),
          fontWeight: '500',
          lineHeight: '150%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flexStart',
          '& img': {
            marginTop: `-${rem(6)}`,
            width: `${rem(18)} !important`,
            maxHeight: `${rem(18)} !important`,
            marginRight: rem(10),
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: rem(18),
            lineHeight: '111%',
          },
        },
        '& p': {
          color: COLOR.MILD_GRAY,
          fontSize: rem(16),
          marginBlockStart: '0',
          marginBlockEnd: '0',
          paddingBottom: rem(16),
          paddingLeft: rem(27),
        },
      },
      '.checkmark-list': {
        paddingLeft: '0 !important',
        listStyleType: 'none',
        paddingInlineStart: '0',
        '& li': {
          paddingLeft: '0',
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: rem(18),
            height: rem(18),
            backgroundSize: rem(18),
            backgroundImage: "url('assets/Check-Mark.svg')",
            backgroundRepeat: 'no-repeat',
            marginRight: rem(16),
            backgroundPosition: 'center center',
            verticalAlign: 'middle',
            marginBottom: rem(2),
            [theme.breakpoints.up('lg')]: {
              width: rem(24),
              height: rem(24),
              backgroundSize: rem(24),
              marginBottom: rem(5),
              marginRight: rem(9),
            },
          },
        },
        '& h4': {
          display: 'inline',
          fontSize: rem(18),
          paddingBottom: '0',
          color: theme.palette.primary.main,
          fontWeight: '500',
          lineHeight: '111%',
          [theme.breakpoints.up('lg')]: {
            fontSize: rem(24),
          },
        },
        '& p': {
          color: COLOR.MILD_GRAY,
          marginBlockStart: '0',
          marginBlockEnd: '0',
          paddingBottom: rem(16),
          paddingLeft: rem(38),
          [theme.breakpoints.up('lg')]: {
            paddingLeft: rem(36),
          },
        },
      },
      '.simple-quote': {
        textIndent: '-0.45rem',
        paddingLeft: '0',
        color: theme.palette.primary.dark,
        paddingBottom: rem(16),
      },
      '.callout-quote': {
        ...theme.typography.quote,
        textIndent: '-0.45rem',
        paddingLeft: '0',
        color: theme.palette.primary.dark,
        paddingBottom: rem(16),
      },
      '.normal-attribution': {
        marginBlockStart: '0',
        marginBlockEnd: '1rem',
        marginInlineStart: '0',
        marginInlineEnd: '0',
        paddingLeft: 0,
        position: 'relative',
        paddingBottom: rem(40),
        '& h4': {
          paddingBottom: rem(0),
          textTransform: 'none',
          paddingLeft: rem(12),
          [theme.breakpoints.up('md')]: {
            paddingBottom: rem(4),
          },
        },
        '& p': {
          color: theme.palette.primary.dark,
          paddingLeft: rem(12),
          paddingBottom: 0,
        },
        '&:before': {
          position: 'absolute',
          content: '""',
          height: '55%',
          width: '2px',
          paddingRight: rem(5),
          borderLeft: `2px solid ${theme.palette.primary.light}`,
        },
      },
      '.simple-attribution': {
        marginBlockStart: '0',
        marginBlockEnd: '1rem',
        marginInlineStart: '0',
        marginInlineEnd: '0',
        paddingLeft: 0,
        position: 'relative',
        paddingBottom: rem(40),
        '& h4': {
          ...theme.typography.body.bold,
          textTransform: 'none',
          fontSize: rem(16),
          paddingBottom: rem(4),
          paddingLeft: rem(12),
        },
        '& p': {
          color: theme.palette.primary.dark,
          fontSize: rem(16),
          paddingLeft: rem(12),
          paddingBottom: 0,
        },
        '&:before': {
          position: 'absolute',
          content: '""',
          height: '55%',
          width: '2px',
          paddingRight: '5px',
          borderLeft: `2px solid ${theme.palette.primary.light}`,
        },
      },
    },
    shareBlock: {
      width: rem(72),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${rem(40)} ${rem(0)} ${rem(0)} ${rem(24)}`,
      gap: rem(32),
      color: COLOR.MID_GRAY,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(64)} ${rem(0)} ${rem(0)} ${rem(40)}`,
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
        padding: `${rem(70)} ${rem(0)}`,
      },
    },
    socialMedia: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `0`,
      gap: rem(16),
      color: COLOR.MID_GRAY,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
      },
    },
    iconBtn: {
      width: rem(32),
      height: rem(32),
      [theme.breakpoints.up('md')]: {
        width: rem(32),
        height: rem(32),
      },
    },
    print: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `0`,
      gap: rem(16),
      color: COLOR.MID_GRAY,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
      },
    },
    infoBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: rem(24),
      gap: rem(24),
      margin: `${rem(0)} ${rem(24)} ${rem(40)} ${rem(24)}`,
      backgroundColor: COLOR.BAKER_BEACH_WHITE,
      height: '100%',
      minHeight: 0,
      '& hr': {
        width: '100%',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        marginBottom: rem(24),
        background: COLOR.BAKER_BEACH_GRAY,
        height: rem(2),
        border: 0,
      },
      [theme.breakpoints.up('md')]: {
        margin: `${rem(0)} ${rem(40)} ${rem(65)} ${rem(40)}`,
      },
      [theme.breakpoints.up('lg')]: {
        width: '29.29%',
        margin: `${rem(64)} ${rem(0)}`,
      },
      [theme.breakpoints.up('xl')]: {
        width: rem(360),
      },
    },
    infoBlockItem: {
      ...theme.typography.body.default,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: COLOR.DARK_GRAY,
      fontSize: rem(16),
      '& p': {
        color: COLOR.DARK_GRAY,
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
      },
      '& ol': {
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
        paddingInlineStart: rem(30),
      },
      '& ul': {
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
        paddingInlineStart: rem(28),
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    infoItemHeading: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: rem(8),
    },
    headingItem: {
      ...theme.typography.h4,
      lineHeight: '111%',
      paddingTop: rem(5),
      color: theme.palette.primary.dark,
      marginBlockStart: '0',
      marginBlockEnd: '0',
    },
    additionalInfo: {
      ...theme.typography.body.default,
      width: '100%',
      color: COLOR.DARK_GRAY,
      fontSize: rem(16),
      '& p': {
        color: COLOR.DARK_GRAY,
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
      },
      '& ol': {
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
        paddingInlineStart: rem(30),
      },
      '& ul': {
        marginBlockStart: rem(4),
        marginBlockEnd: rem(4),
        paddingInlineStart: rem(28),
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
  }
})
const video_Props = {
  video: {
    videoId: '',
    autoPlay: false,
    controls: true,
    loop: false,
    allowFullScreen: true,
    muteByDefault: false,
  },
}

const MyComp = (Props, smallVideo) => (
  <div className={smallVideo ? 'videoWrapperSmall' : 'videoWrapperBig'}>
    <Video {...Props} />
  </div>
)
function LongDescription(props) {
  const { data } = props
  const [url, setUrl] = useState('')
  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }
  const { long_description, section_id } = data
  const {
    indent,
    background_color,
    show_page_sharing_options,
    show_print_option,
    show_information_panel,
    tag_selection = 'h2',
    style_selection = 'h2',
  } = long_description
  const { classes } = useStyles({
    indent,
    background_color,
    show_page_sharing_options,
    show_print_option,
    show_information_panel,
  })

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))

  useEffect(() => {
    setUrl(window.location.href)
    const videoLink = document.querySelectorAll('.youtube')
    videoLink?.forEach((i) => {
      const videoWidth = i.getAttribute('class').split(' ')
      const smallVideo = videoWidth.includes('small_video')
      const videoId = i.getAttribute('href').split('=')
      // eslint-disable-next-line prefer-destructuring
      video_Props.video.videoId = videoId[1]
      const myComp = MyComp(JSON.parse(JSON.stringify(video_Props)), smallVideo)
      const newElement = document.createElement('div')
      newElement.className = 'videoWrapperDiv'
      i.parentElement.replaceWith(newElement)
      const portalThatInsertsMyCompIntoHead = ReactDOM.createPortal(
        myComp,
        newElement
      )

      ReactDOM.render(
        portalThatInsertsMyCompIntoHead,
        document.createElement('div')
      )
      i.style.display = 'none'
    })
  }, [])

  const gaTag = 'wysiwyg'
  return (
    <div
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
    >
      {(long_description.show_page_sharing_options ||
        long_description.show_print_option) && (
        <div className={classes.shareBlock}>
          {long_description.show_page_sharing_options && (
            <div className={classes.socialMedia}>
              <Typography variant="label">SHARE</Typography>

              <FloatingActionButton
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                    'MyWindow',
                    'width=600,height=300'
                  )
                }
                className={classes.iconBtn}
                title="Share on facebook"
                aria-label="Share on facebook"
                data-ga-location="wysiwyg"
              >
                <FbIcon width="8" height="15" />
              </FloatingActionButton>

              <FloatingActionButton
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?text=${long_description.heading}&url=${url}`,
                    'MyWindow',
                    'width=600,height=300'
                  )
                }
                className={classes.iconBtn}
                title="Share on twitter"
                aria-label="Share on twitter"
                data-ga-location="wysiwyg"
              >
                <XCorp width="15" height="12" />
              </FloatingActionButton>
              <FloatingActionButton
                onClick={() =>
                  window.open(
                    `mailto:?subject=Interesting information&body=I thought you might find this information interesting: ${url}`,
                    'MyWindow',
                    'width=600,height=300'
                  )
                }
                className={classes.iconBtn}
                title="Share with email"
                aria-label="Share with email"
                data-ga-location="wysiwyg"
              >
                <Mail width="20" height="16" />
              </FloatingActionButton>
            </div>
          )}
          {long_description.show_print_option && (
            <div className={classes.print}>
              <Typography variant="label">PRINT</Typography>
              <FloatingActionButton
                onClick={() => window.print()}
                className={classes.iconBtn}
                title="Print"
                aria-label="Print"
                data-ga-location="wysiwyg"
              >
                <Print width="18" height="18" />
              </FloatingActionButton>{' '}
            </div>
          )}
        </div>
      )}
      <div className={classes.wrapper}>
        {long_description.heading && (
          <Typography
            component={tag_selection}
            variant={style_selection}
            style={{
              ...theme.typography[style_selection],
              paddingBottom: rem(24),
              ...(style_selection === 'h3' && {
                fontSize: mdUp ? rem(32) : rem(24),
                textTransform: 'unset',
              }),
              ...(style_selection === 'h2' && {
                fontSize: mdUp ? rem(48) : rem(40),
              }),
            }}
          >
            {long_description.heading}
          </Typography>
        )}
        <div>{sanitize(long_description.long_desc, gaTag)}</div>
      </div>

      {long_description.show_information_panel && (
        <div className={classes.infoBlock}>
          {long_description?.information_panel?.map((item, index) => (
            <div key={`info${index}`} className={classes.infoBlockItem}>
              <div className={classes.infoItemHeading}>
                {item?.panel_icon && (
                  <Image
                    src={item?.panel_icon?.url}
                    alt={item?.panel_icon?.alt || 'icon'}
                    title={item?.panel_icon?.title}
                    width="26px"
                    height="26px"
                  />
                )}
                <div className={classes.headingItem}>{item.panel_heading}</div>
              </div>
              <div style={{ width: '100%' }}>
                {sanitize(item.panel_text, gaTag)}
              </div>
            </div>
          ))}
          <div className={classes.additionalInfo}>
            <div style={{ width: '100%' }}>
              {sanitize(long_description.additional_info, gaTag)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LongDescription

LongDescription.propTypes = {
  data: PropTypes.shape({
    long_description: PropTypes.shape({
      heading: PropTypes.string,
      tag_selection: PropTypes.oneOf(['h2', 'h3']),
      style_selection: PropTypes.oneOf(['h2', 'h3']),
      long_desc: PropTypes.string,
      show_page_sharing_options: PropTypes.bool,
      show_print_option: PropTypes.bool,
      show_information_panel: PropTypes.bool,
      information_panel: PropTypes.array,
      additional_info: PropTypes.string,
      background_color: PropTypes.string,
      indent: PropTypes.bool,
    }),
  }),
}

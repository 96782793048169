import React from 'react'

function Print(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '20'}
      height={height || '19'}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16671 1.16665C4.16671 0.706409 4.5398 0.333313 5.00004 0.333313H15C15.4603 0.333313 15.8334 0.706409 15.8334 1.16665V6.16665H16.6667C17.3297 6.16665 17.9656 6.43004 18.4345 6.89888C18.9033 7.36772 19.1667 8.0036 19.1667 8.66665V12.8333C19.1667 13.4964 18.9033 14.1322 18.4345 14.6011C17.9656 15.0699 17.3297 15.3333 16.6667 15.3333H15.8334V17.8333C15.8334 18.2936 15.4603 18.6666 15 18.6666H5.00004C4.5398 18.6666 4.16671 18.2936 4.16671 17.8333V15.3333H3.33337C2.67033 15.3333 2.03445 15.0699 1.56561 14.6011C1.09677 14.1322 0.833374 13.4964 0.833374 12.8333V8.66665C0.833374 8.0036 1.09677 7.36772 1.56561 6.89888C2.03445 6.43004 2.67033 6.16665 3.33337 6.16665H4.16671V1.16665ZM5.83337 14.5L5.83337 17H14.1667V14.5013C14.1667 14.5008 14.1667 14.5004 14.1667 14.5C14.1667 14.4996 14.1667 14.4991 14.1667 14.4987V12H5.83337L5.83337 14.5ZM15.8334 13.6666V11.1666C15.8334 10.7064 15.4603 10.3333 15 10.3333H5.00004C4.5398 10.3333 4.16671 10.7064 4.16671 11.1666V13.6666H3.33337C3.11236 13.6666 2.9004 13.5788 2.74412 13.4226C2.58784 13.2663 2.50004 13.0543 2.50004 12.8333V8.66665C2.50004 8.44563 2.58784 8.23367 2.74412 8.07739C2.9004 7.92111 3.11236 7.83331 3.33337 7.83331L5.00004 7.83331H15L16.6667 7.83331C16.8877 7.83331 17.0997 7.92111 17.256 8.07739C17.4122 8.23367 17.5 8.44563 17.5 8.66665V12.8333C17.5 13.0543 17.4122 13.2663 17.256 13.4226C17.0997 13.5788 16.8877 13.6666 16.6667 13.6666H15.8334ZM14.1667 1.99998V6.16665H5.83337V1.99998H14.1667Z"
        fill={color || '#FEFCF8'}
      />
    </svg>
  )
}

export default Print
